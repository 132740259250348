export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "DISTRITO",
    dataIndex: "distrito",
    key: "distrito",
    sorter: (a, b) => a.distrito.localeCompare(b.distrito),
    width: 140
  },
  {
    title: "PORCENTAJE",
    key: "porcentaje",
    dataIndex: "porcentaje",
    width: 180,
    sorter: (a, b) => a.porcentaje - b.porcentaje,
    slots: { customRender: "porcentaje" },
    defaultSortOrder: "descend"
  },
  {
    title: "NUM/DEN",
    key: "numeradordenominador",
    width: 100,
    slots: { customRender: "numeradordenominador" }
  }
];
