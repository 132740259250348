<template>
  <div style="display: none">
    <div id="printMapaCalor">
      <PrintContainer tituloReporte="REPORTE DE MAPA DE CALOR DE GESTANTES CON ANEMIA">
        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">DISTRITO</th>
              <th class="text-center p-3 border border-black">NUMERADOR</th>
              <th class="text-center p-3 border border-black">DENOMINADOR</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(row, index) in establecimientos" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ row.distrito }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ row.total_numerador_establecimiento }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ row.total_denominador_establecimiento }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ row.porcentaje  }} %
              </td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: PADRÓN NOMINAL, HISMINSA y CNV actualizado a la fecha: 15/11/2021
              </td>
            </tr>
          </PrintFooter>
        </table>
      </PrintContainer>
    </div>
  </div>
</template>

<script>
import PrintContainer from "./PrintContainer.vue";
import PrintFooter from "@/components/PrintFooter.vue";

export default {
  components: {
    PrintContainer,
    PrintFooter
  },
  props: {
    establecimientos: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    return {};
  }
};
</script>

<style scoped></style>
