<template>
  <a-form :model="formState" class="flex flex-col md:flex-row w-full gap-2" layout="vertical">
    <!--<a-form-item label="Año" name="search" class="mb-0 w-full lg:w-44">
      <a-select class="w-56" @select="onSelectAnio" v-model:value="formState.anio">
        <a-select-option v-for="anio in anios" :value="anio" :key="anio">
          {{ anio }}
        </a-select-option>
      </a-select>
    </a-form-item>-->
    <a-form-item label="Año" name="fecha_corte" class="w-full">
      <a-select v-model:value="formState.fecha_corte" @select="onSelectFechaCorte">
        <a-select-option v-for="p in anios" :value="p" :key="p">
          {{ p }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="PROVINCIA" name="provincia" class="w-full">
      <a-select v-model:value="formState.provincia" @select="onSelectProvincia">
        <a-select-option key="all" value="">TODAS</a-select-option>
        <a-select-option v-for="p in provincias" :value="p.provincia" :key="p.provincia">
          {{ p.provincia }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="DISTRITO" name="distrito" class="w-full">
      <a-select v-model:value="formState.distrito" @select="onSelectDistrito">
        <a-select-option key="all" value="">TODOS</a-select-option>
        <a-select-option v-for="d in distritos" :value="d.distrito" :key="d.distrito">
          {{ d.distrito }}
        </a-select-option>
      </a-select>
    </a-form-item>
  </a-form>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import IndicadorApi from "@/api/indicador";
import { useStore } from "vuex";
import moment from "moment";

export default {
  props: {
    periodos: {
      type: Array,
      default: () => []
    }
  },
  emits: ["fetchEstablecimientos", "getAllByProvincia"],
  setup(props, { emit }) {
    const store = useStore();
    const provincias = ref([]);
    const distritos = ref([]);
    const anios = ref([]);

    const formState = reactive({
      idperiodo: "",
      provincia: "",
      distrito: "",
      anio: "",
      fecha_corte: ""
    });

    const fetchProvincias = () => {
      IndicadorApi.getProvincias()
        .then((response) => {
          provincias.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const fetchDistritos = (provincia = "") => {
      IndicadorApi.getDistritos(provincia)
        .then((response) => {
          distritos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      fetchAnios();
    });

    const fetchAnios = () => {
      IndicadorApi.getWithGroupFechaGestantes()
        .then((response) => {
          var oldanios = [];
          for (let index = 0; index < response.data.length; index++) {
            oldanios.push(response.data[index]["anio"]);
          }
          anios.value = oldanios;

          formState.fecha_corte = oldanios[0];

          store.dispatch("mapa/setFechaCorte", formState.fecha_corte);

          fetchProvincias();
          fetchDistritos();
        })
        .catch((err) => console.log(err));
    };

    const onSelectProvincia = (provincia) => {
      store.dispatch("mapa/setProvincia", provincia);
      store.dispatch("mapa/setUbigeo", "");
      store.dispatch("mapa/setDistrito", "");

      formState.distrito = "";
      emit("fetchEstablecimientos");
      emit("getAllByProvincia");
      fetchDistritos(provincia);
    };

    const onSelectPeriodo = (periodo) => {
      store.dispatch("mapa/setPeriodo", periodo);
      emit("fetchEstablecimientos");
      emit("getAllByProvincia");
    };

    const onSelectDistrito = (ubigeo) => {
      store.dispatch("mapa/setDistrito", ubigeo);
      emit("fetchEstablecimientos");
      emit("getAllByProvincia");
    };

    const onSelectAnio = (anio) => {
      store.dispatch("mapa/setAnio", anio);
      emit("fetchEstablecimientos");
      emit("getAllByProvincia");
    };

    const onSelectFechaCorte = (fecha) => {
      store.dispatch("mapa/setFechaCorte", formState.fecha_corte);
      emit("fetchEstablecimientos");
      emit("getAllByProvincia");
    };

    const cleanFilters = () => {
      formState.idperiodo = "";
      formState.provincia = "";
      formState.distrito = "";

      store.dispatch("mapa/setProvincia", "");
      store.dispatch("mapa/setPeriodo", "");
      store.dispatch("mapa/setUbigeo", "");

      emit("fetchEstablecimientos");
      emit("getAllByProvincia");

      fetchProvincias();
      fetchDistritos();
    };

    return {
      formState,
      provincias,
      distritos,
      onSelectPeriodo,
      onSelectProvincia,
      onSelectDistrito,
      cleanFilters,
      anios,
      onSelectAnio,
      onSelectFechaCorte
    };
  }
};
</script>

<style scoped></style>
